import { AuthProvider } from "react-oidc-context";
import { BubbleChat } from 'flowise-embed-react'
import React from 'react';
import './src/styles/global.css'

export const wrapRootElement = ({ element }) => {
  const oidcConfig = {
    authority: `${process.env.GATSBY_OIDC_AUTHORITY}`,
    client_id: `${process.env.GATSBY_OIDC_CLIENT_ID}`,
    redirect_uri: `${process.env.GATSBY_OIDC_REDIRECT_URI}`,
    onSigninCallback: () => {
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  };

  return <>
          <BubbleChat
            chatflowid="080cd40b-715c-4ff4-a436-59149e6d5ea8"
            apiHost="https://flow.beyond.cc"
            theme={{
                chatWindow: {
                    poweredByTextColor: "#ffffff",
                    botMessage: {
                        avatarSrc: "https://git.beyond.cc/avatars/66efcb3e52aee09823d37f6c140886445c23102e7e56fd54ff126c71389a4020?size=280",
                    }
                }
            }}
        />
    <AuthProvider {...oidcConfig}>{element}</AuthProvider></>;
};
